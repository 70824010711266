import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import icoSpotify from "super-tiny-icons/images/svg/spotify.svg"
import icoSoundCloud from "super-tiny-icons/images/svg/soundcloud.svg"
import icoYoutube from "super-tiny-icons/images/svg/youtube.svg"
import icoAppleMusic from "super-tiny-icons/images/svg/apple_music.svg"
import icoAmazon from "super-tiny-icons/images/svg/amazon.svg"
import icoX from "super-tiny-icons/images/svg/x.svg"
import icoInstagram from "super-tiny-icons/images/svg/instagram.svg"
import icoBandcamp from "super-tiny-icons/images/svg/bandcamp.svg"
import {Avatar} from "@mui/material";

export default function MediaControlCard(props) {
    const {song, setUrl} = props
    const {url, title, artist, album, image, src, social} = song

    return (
        <Card sx={{ display: 'flex', justifyContent:'space-between', height:'100%', background:'url(/assets/bg2.jpg)', backgroundSize:'200%', boxShadow: 12 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex:1 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h4" sx={{fontFamily:'Edo', color:'#f3f951', textShadow: '2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000'}}>
                        {title}
                    </Typography>
                    <Typography variant="h5" color="text.secondary" component="div" sx={{color:'#37f6be', fontFamily:'Edo', textShadow: '2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000'}}>
                        {artist}
                    </Typography>
                    {album && <Typography variant="subtitle2" color="text.secondary" component="div">
                        from {album}
                    </Typography>}
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', pr: 1, pb: 1 }}>
                        {social.spotify && <a href={social.spotify} rel="noreferrer" target={'_blank'}><Avatar src={icoSpotify} sx={{width:24, height:24}} /></a>}
                        {social.soundcloud && <a href={social.soundcloud} rel="noreferrer" target={'_blank'}><Avatar src={icoSoundCloud} sx={{width:24, height:24}} /></a>}
                        {social.amazon && <a href={social.amazon} rel="noreferrer" target={'_blank'}><Avatar src={icoAmazon} sx={{width:24, height:24}} /></a>}
                        {social.apple && <a href={social.apple} rel="noreferrer" target={'_blank'}><Avatar src={icoAppleMusic} sx={{width:24, height:24}} /></a>}
                        {social.youtube && <a href={social.youtube} rel="noreferrer" target={'_blank'}><Avatar src={icoYoutube} sx={{width:24, height:24}} /></a>}
                        {social.x && <a href={social.x} rel="noreferrer" target={'_blank'}><Avatar src={icoX} sx={{width:24, height:24}} /></a>}
                        {social.instagram && <a href={social.instagram} rel="noreferrer" target={'_blank'}><Avatar src={icoInstagram} sx={{width:24, height:24}} /></a>}
                        {social.bandcamp && <a href={social.bandcamp} rel="noreferrer" target={'_blank'}><Avatar src={icoBandcamp} sx={{width:24, height:24}} /></a>}
                    </Box>
                </CardContent>
                <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <IconButton aria-label="play/pause">
                            <PlayArrowIcon sx={{ height: 38, width: 38, color:'#f3f951' }} onClick={()=>{setUrl(url)}}/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={image || src}
                alt={title}
            />
        </Card>
    );
}