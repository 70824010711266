import './App.css';
import ReactPlayer from 'react-player/lazy'
import SongCard from './components/SongCard'
import React, {createRef, useState} from "react";
import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

function App() {
    const player = createRef()
    const [url, setUrl] = useState()

    const playlist = [
        {
            url: '/assets/Nick Nitolli - paranoid american v3.5.mp3',
            title: 'Paranoid',
            artist: 'Nick Nittoli',
            src: '/assets/nick_nitolli.png',
            social: {
                instagram: 'https://www.instagram.com/nicknittoli/',
                x: 'https://x.com/NickNittoli',
                soundcloud: 'https://soundcloud.com/nicknittoli',
                youtube: 'https://www.youtube.com/@nicknittoli',
                spotify: 'https://open.spotify.com/track/3G1wJWUbT1Li6NmFr91Rmj?si=bdb266b371a54f90',
                apple: 'https://music.apple.com/us/artist/nick-nittoli/1239535690',
                amazon: 'https://music.amazon.com/artists/B002A5Z0W6/nick-nittoli',
            }
        },
        {
            url: '/assets/TruthSeekah THE SOUND OF FREEDOM.mp3',
            title: 'The Sound of Freedom',
            artist: 'TruthSeekah',
            src: '/assets/truthseekah.png',
            social: {
                spotify: 'https://open.spotify.com/artist/6DJTPnzX7DrhXGPvpyme39?si=bH8MBw7xQGG8k8SkHaZA_g&nd=1&dlsi=c78c98b780974363',
                instagram: 'https://www.instagram.com/truthseekah/',
                soundcloud: 'https://soundcloud.com/truthseekah',
                youtube: 'https://www.youtube.com/@truthseekahmusicvideosoffi7535',
                apple: 'https://music.apple.com/us/artist/truthseekah/293500242',
                bandcamp: 'https://truthseekah.bandcamp.com/',
            }
        },
        {
            url: '/assets/Joel Thomas - Hypnotica.mp3',
            title: 'Hypnotica',
            artist: 'Joel Thomas',
            image: 'https://pbs.twimg.com/profile_images/1721942667364286464/FhhnDil7_400x400.jpg',
            social: {
                instagram: 'https://www.instagram.com/joelthomasmedia',
                youtube: 'https://www.youtube.com/@JoelThomasMedia',
                spotify: 'https://open.spotify.com/artist/4tQylLct2oBQ6hs6YxOvVG?si=P42gr4saThOHKH-UfwryLw',
                apple: 'https://music.apple.com/us/artist/joel-thomas/1324810531',
                amazon: 'https://music.amazon.com/artists/B0014J2HZW',
                x: 'https://twitter.com/joelthomasmedia',
            }
        },
        {
            url: 'https://soundcloud.com/truth-psyrum/hard-in-the-paint-wav',
            title: 'HARD iN THE PAiNT',
            artist: 'Truth Psyrum',
            image: 'https://i1.sndcdn.com/artworks-al5yFHpxfMKuzQti-eC9aBQ-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/truth-psyrum'
            }
        },
        {
            url: 'https://www.youtube.com/watch?v=qAosXUmy-EM',
            title: 'Purebloods',
            artist: 'Venice Beach Dub Club',
            src: '/assets/vbdc.png',
            social: {
                instagram: 'https://www.instagram.com/Venicebeachdubclub',
                youtube: 'https://www.youtube.com/@VBDC_Music',
                spotify: 'https://open.spotify.com/artist/7uNDutjQhF68H9a2D47Qjb',
                apple: 'https://music.apple.com/us/artist/venice-beach-dub-club/1440957086',
            }
        },
        {
            url: '/assets/Mad 1 - No Thanx.mp3',
            title: 'No Thanx',
            artist: 'Mad 1',
            image: 'https://pbs.twimg.com/profile_images/1025585865706287104/Y4TiJ36H_400x400.jpg',
            social: {
                instagram: 'https://www.instagram.com/king_mad1one',
                x: 'https://twitter.com/MAD1ONE',
                youtube: 'https://www.youtube.com/channel/UCSuBKGC3ZKgyp0xBM-zvl-g',
                bandcamp: 'https://mad1one.bandcamp.com/music',
            }
        },
        {
            url: '/assets/Spade Morningstar - 6. Bong rips and acid trips.mp3',
            title: 'Bong Rips and Acid Trips',
            artist: 'Spade Morningstar',
            src: '/assets/spade_morningstar.png',
            social: {
                instagram: 'https://www.instagram.com/spademorningstarmusic',
                soundcloud: 'https://soundcloud.com/spademorningstar/tracks',
                youtube: 'https://www.youtube.com/@spademorningstar7415',
                spotify: 'https://open.spotify.com/artist/3lvw9y5Ts6wLZ7R6cgF6WT',
                apple: 'https://music.apple.com/us/artist/spade-morningstar/1516037216',
                amazon: 'https://www.amazon.com/music/player/artists/B089DXVQXW/spade-morningstar',
            }
        },
        {
            url: 'https://soundcloud.com/true-one/02-how-can-i-trust-produced-by',
            title: 'How Can I Trust?',
            artist: 'True One',
            image: 'https://i1.sndcdn.com/avatars-7GYeShyqB2MEz5T0-AzL3KA-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/true-one',
            }
        },
        {
            url: 'https://soundcloud.com/tunnelmental/hold-on-by-tunnelmental',
            title: 'Hold On',
            artist: 'Tunnelmental',
            image: 'https://i1.sndcdn.com/avatars-000280797652-3tbi0n-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/tunnelmental',
            }
        },
        {
            url: 'https://soundcloud.com/seanchrisofficial/american-psyop',
            title: 'American Psyop',
            artist: 'Sean Chris',
            image: '/assets/sean_chris.png',
            social: {
                amazon: 'https://www.amazon.com/music/player/artists/B00585VNPI/sean-chris',
                apple: 'https://music.apple.com/us/artist/sean-chris/1553075847',
                instagram: 'https://www.instagram.com/killthemockingbirdspodcast/',
                spotify: 'https://open.spotify.com/artist/38LMiDxurSIaFsEG2vgeDy?si=sO-Sg1bpRXeQumMte78BGg&nd=1&dlsi=846362b0716149c8',
                soundcloud: 'https://soundcloud.com/seanchrisofficial',
            }
        },
        {
            url: 'https://soundcloud.com/praze-em/expect-us',
            title: 'Expect Us',
            artist: 'J-SPECISH',
            image: 'https://i1.sndcdn.com/artworks-000110668331-2gy9rr-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/jspecish',
            }
        },
        {
            url: 'https://www.youtube.com/watch?v=6LI3HCXLX1o',
            title: 'Chemtrails Dem Real',
            artist: 'Sound Scientists',
            image: 'https://i1.sndcdn.com/avatars-000000584504-6ado6o-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/paranoid-american-set-me-free',
            title: 'Paranoid American',
            artist: 'Fishbayne',
            image: 'https://i1.sndcdn.com/artworks-4889XN9Pd0p7DISU-Z0Um8A-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/lets-make-some-nephilim',
            title: 'Let\'s Make Some Nephilim',
            artist: 'CloudStryfe',
            image: 'https://i1.sndcdn.com/artworks-DdPMx36CeWxpXDm9-f4gL5Q-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/hunter-biden-f-a-laptop',
            title: 'Hunter Biden',
            artist: 'Big Guy 10p',
            image: 'https://i1.sndcdn.com/artworks-JokqvLVYlfVXyKuk-4FkajQ-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/talking-to-spirits',
            title: 'Talking to Spirits',
            artist: 'Sound Scientists',
            image: 'https://i1.sndcdn.com/artworks-UHLtcp3JkUjl5uyX-yEMP8g-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/true-world-order',
            title: 'True World Order',
            artist: 'True One',
            image: 'https://i1.sndcdn.com/avatars-7GYeShyqB2MEz5T0-AzL3KA-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/true-one',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/33rd-degree-ghosts-of-alchemy',
            title: '33rd Degree',
            artist: 'Pale Whores',
            image: 'https://i1.sndcdn.com/artworks-19pLN8JVxZjzs7gv-M9MzHw-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/pizzagate-frazzledrip',
            title: 'Pizzagate, Frazzledrip',
            artist: 'Lil\' Fanta',
            image: 'https://i1.sndcdn.com/artworks-bK8SW7z8OKr7CmC6-LOdrMQ-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/body-bags-leo-ryan',
            title: 'Body Bags',
            artist: 'Leo Ryan',
            image: 'https://i1.sndcdn.com/artworks-dUoahAx2RMM8dlVj-LOqVew-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/got-that-dmt-like-rene-descartes',
            title: 'Got That DMT',
            artist: 'Rene Descartes',
            image: 'https://i1.sndcdn.com/artworks-lc16fAUNuCC2olsY-Nic6UA-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/conspiracy-theories',
            title: 'Conspiracy Theories',
            artist: 'Dijonaise',
            image: 'https://i1.sndcdn.com/artworks-AHoPbtxow2vJUj3G-VBWo6w-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/i-woke-up-one-morning-jet-fuel-cant-melt-steel-beams',
            title: 'Jet Fuel Can\'t Melt Steel Beams',
            artist: 'sara toate',
            image: 'https://i1.sndcdn.com/artworks-ZSSK1sV4530koFBc-bLfpJg-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/hollywood-wicked-place',
            title: 'Hollywood, Wicked Place',
            artist: 'Laryn Lowe',
            image: 'https://i1.sndcdn.com/artworks-QHfd5yzZc1CIztpx-zJPhyg-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
        {
            url: 'https://soundcloud.com/soundscientists/the-hole-in-jfks-head',
            title: 'The Hole in JFK\'s Head',
            artist: 'Jackie O',
            image: 'https://i1.sndcdn.com/artworks-17bevHb6P93zo7de-IPsziA-t500x500.jpg',
            social: {
                soundcloud: 'https://soundcloud.com/soundscientists/tracks',
                spotify: 'https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ',
                apple: 'https://music.apple.com/us/artist/sound-scientists/386387248',
                bandcamp: 'https://soundscientists.bandcamp.com/album/instrumentally-sound',
                amazon: 'https://www.amazon.com/music/player/artists/B003YPDM58/sound-scientists',
                youtube: 'https://www.youtube.com/watch?v=GTq6gDlUliU&list=PLzjBdTWVREn_hiirnNLfZwgCinGtgE-Zj',
                instagram: 'https://instagram.com/paranoidamerican',
                x: 'https://twitter.com/paranoidamerica',
            }
        },
    ]

  return (
    <div className="App">
      <header className="App-header">
        <Typography variant={'h1'} component={'h1'} mt={6} mb={2} sx={{color:"#f3f951", fontFamily:'Edo', textShadow: '4px 0 0 #000, 0 -4px 0 #000, 0 4px 0 #000, -4px 0 0 #000'}}>Tin Foil Trap</Typography>
          <Typography variant={'h6'} component={'h2'} mb={4}>
          brought to you by <a href={'https://paranoidamerican.com/'} style={{color:'white'}}>ParanoidAmerican.com</a> and <a href={'https://HipHopProduction.com'} style={{color:'white'}}>HipHopProduction.com</a>
          </Typography>
          <Container maxWidth={'lg'}>
              <Box sx={{marginBottom:'60px'}}>
                <ReactPlayer playing ref={player} url={url} width={'100%'} controls height={url ? undefined : '0'} />
              </Box>
              <Grid2 container spacing={2} mb={8} sx={{alignItems:'stretch'}}>
                  {playlist.map(playlistitem=> {
                      return <Grid2 xs={12} md={6} lg={4} sx={{marginBottom:'20px'}}>
                          <Box sx={{height:'100%'}}>
                              <SongCard
                              song={playlistitem}
                              setUrl={setUrl}/>
                          </Box>
                      </Grid2>
                  })}
              </Grid2>
              <Box mb={8}>
                  <Typography variant={'body1'}>
                      All music copyrights belong to respective artists. <a href={"https://paranoidamerican.com/pages/about-us"} style={{color:'white'}}>Want your music featured? Visit ParanoidAmerican.com</a>.
                  </Typography>
              </Box>
          </Container>
      </header>
    </div>
  );
}

export default App;
